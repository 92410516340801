._wysiwyg {
  a {
    text-decoration: underline;
  }
  ul {
    li {
      position: relative;
      &:before {
        content: '- ';
      }
    }
  }
}
