.notfound {
  text-align: center;
  padding: 20vh 0 10vh;
  max-width: 60rem;
  margin: auto;
  &__title {
    font-size: 4rem;
    line-height: 1.13;
    font-weight: 700;

  }
  &__subtitle {
    padding: 1.8rem 0;
    font-size: 4rem;
    line-height: 1.13;

  }
  &__text {
    p {
      margin: 0;
    }
  }
}