.title {
  position: relative;
  overflow: hidden;
  width: 100%;
  transition: padding 360ms var(--e);

  &__no {
    white-space: nowrap;
    transition: transform 360ms var(--e);
    transform: scale(0, 1);
    position: absolute;
    transform-origin: 0% 50%;
    font-weight: 700;
    top: 0.5rem;
    left: 0rem;
    color: #fff;
    @include portrait {
      top: 2.4rem;
    }
    @include tablet {
      top: 2.2rem;
    }
  }
}
