.sticker {
  align-self: flex-start;
  &:after {
    content: '';
    position: absolute;
    transition: opacity 200ms var(--e);
    top: -1.2rem;
    bottom: -1.2rem;
    // bottom: 0;
    pointer-events: none;
    left: -2rem;
    right: -2rem;
    opacity: 0;
    top: -17rem;
    z-index: -1;
    background-image: linear-gradient(
      to top,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 1.2rem,
      rgba(0, 0, 0, 1) 100%
    );
  }
  &.is-stuck {
    color: #fff;
    &:after {
      opacity: 1;
    }
  }
}
