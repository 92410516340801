.block {
  &__title {
    font-size: 2rem;
    line-height: 1.2;
    border-bottom: solid 2px #fff;
    padding: 1.3rem 0;
    @include portrait {
      min-height: 7.4rem;
    }
  }

  &__content {
    p {
      margin-top: 0;
      margin: 0.7em 0 0.2em;
    }
  }
}
