.listing {
  &__title {
  }

  &__sections {
    padding: 2rem 0;

    @include portrait {
      padding: 1.8rem 0;
    }
  }

  &__section {
    padding: 1rem 0;

    @include portrait {
      padding: 0rem 0;
    }
  }

  &__letter {
    display: block;
    font-weight: 700;
    padding: 1rem 0;

    @include portrait {
      padding: 0rem 0 1rem;
    }
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    @include flex;
    height: 7rem;
    font-size: 2rem;
    line-height: 1.2;
    max-width: 100%;
    padding: 1.1rem 2.2rem;
    border: solid 1px #fff;
    margin-right: 3rem;
    margin-bottom: 3rem;
    position: relative;
    transition: color 360ms var(--e), opacity 0.6s var(--e), -webkit-transform 0.6s var(--e);

    &:before {
      content: '';
      @include absolute;
      z-index: -1;
      background-color: #fff;
      transform: scale(1, 0);
      transform-origin: 50% 100%;
      transition: transform 360ms var(--e);
    }
    @include hover {
      // color: #000;
      color: #000!important;
      &:before {
        transform: scale(1, 1);
      }
    }

    &:last-child {
      margin-bottom: 0;

      @include portrait {
        margin-bottom: 3rem;
      }
    }
  }
}
