.who {
  &__intro {
    font-size: 2rem;
    line-height: 1.2;

    @include portrait {
    line-height: 1.13;
      font-size: 4rem;
      padding: 1.8rem 0 1.8rem 26.1%;
    }

    @include tablet {
      padding: 1.8rem 0;
    }

    p {
      margin: 0;
    }
  }

  &__block {
    padding: 2.1rem 0 0.2rem;

    @include portrait {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -1rem;
      width: calc(100% + 2rem);
    }
  }

  &__columns {
    @include portrait {
      margin: 0 -1rem;
      padding: 0 1rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-content: flex-start;

      &--full {
        width: calc(100% + 2rem);
      }

      &--two-thirds {
        width: calc(66.66% + 2rem);
      }

      &--third {
        width: calc(33.33% + 2rem);
      }
    }
  }

  &__column {
    @include portrait {
      padding: 0 1rem;

      &--third {
        width: 33.33%;
      }

      &--half {
        width: 50%;
      }

      &--full {
        width: 100%;
      }
    }

    p {
      margin-top: 0;
      width: 100%;
    }
  }

  &__media {
    width: 100%;

    @include portrait {
      margin: 3.6rem 0;
    }

    // height: 28rem;
    // display: flex;
    // align-items: center;
  }

  &__subtitle {
    font-size: 2rem;
    margin: 1rem 0;

    @include portrait {
      border-bottom: solid 1px currentColor;
      margin: 0.7rem 1rem 1rem;
      padding: 1rem 0;
      width: calc(100% - 2rem);
    }
  }
}