.tributes {
  &__intro {
    font-size: 2rem;
    line-height: 1.2;

    @include portrait {
      padding: 3.3rem 27% 1rem 0;
    }

    @include laptop {
      padding: 3.3rem 43% 1rem 0;
    }

    p {
      margin: 0;
    }
  }

  &__sections {
    padding: 3.5rem 0;

    @include portrait {
      padding: 4.5rem 0 2.1rem;
    }

    @include tablet {
      padding: 2.9rem 0 6rem;
    }
  }

  &__section {
    padding-bottom: 5rem;

    @include portrait {
      padding-bottom: 1.3rem;
    }
  }

  &__subtitle {
    font-size: 2rem;
    font-weight: 700;
    line-height: 1.2;
    padding: 1.6rem 0;

    @include portrait {
      font-size: 4rem;
      font-weight: 400;
      line-height: 1.13;
      padding: 0.4rem 0;
    }

    @include tablet {
      padding: 2rem 0 0.4rem;
    }
  }

  &__grid {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1.7rem;
    position: relative;

    @include portrait {
      margin: 0.3rem -1.7rem;
    }
  }

  &__item {
    width: calc(50% - 3.4rem);
    margin: 1.7rem;

    @include portrait {
      width: calc(33.333% - 3.4rem);
    }

    @include tablet {
      width: calc(25% - 3.4rem);
    }

    @include laptop {
      width: calc(16.6666% - 3.4rem);
    }

    &.is-ready {
      will-change: padding;
      transition: padding 250ms var(--e);

      .tributes {
        &__content {
          height: 0;
          overflow: hidden;
          will-change: height;
          transition: height 250ms var(--e);
          opacity: 1;
        }
      }
    }

    &.is-active {
      transition: padding 520ms var(--e) 250ms;

      .tributes {
        &__trigger {
          &:after {
            transform: translate3d(1.4rem, 1.4rem, 0);
          }
        }

        &__content {
          transition: height 520ms var(--e) 250ms;
        }
      }
    }
  }

  &__trigger {
    overflow: visible;
    height: auto;
    width: 100%;
    display: block;
    padding-top: calc(100% - 1.7rem);
    @include hover {
      &:before {
        background-color: #000;
      }
    }
  }

  &__content {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 2;
    opacity: 0;
  }

  &__wrapper {
    padding: 0 1.7rem;
    margin: 3rem 0;
    background-color: #000;

    @include tablet {
      display: flex;
      flex-wrap: wrap;
    }

    @include laptop {
    }
  }

  &__embed {
    display: block;
    width: 100%;

    @include tablet {
      padding-top: 1.4rem;
      padding-right: 3rem;
      width: 51.7%;
    }

    img,
    iframe {
      width: 100%;
      min-height: 20rem;
      object-fit: contain;
      object-position: 50% 0%;
      @include portrait {
        min-height: 40rem;
      }
      @include laptop {
        min-height: 50rem;
      }
    }
  }

  &__text {
    @include portrait {
      padding: 1rem 17.4%;
    }

    @include tablet {
      padding: 0;
      width: 47%;
    }

    @include laptop {
    }

    p {
      margin: 1em 0;
    }
  }
}
