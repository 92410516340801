.award {
  &__featured {
    margin-bottom: 3rem;
    width: 100%;

    @include portrait {
      margin-bottom: 5.6rem;
    }
  }

  &__subtitle {
    font-size: 2rem;
    line-height: 1.2;
  }

  &__credits {
    @include portrait {
      display: flex;
      flex-wrap: wrap;
      ul {
        padding-right: 3rem;
        max-width: 50%;
      }
    }
  }

  &__column {
    @include portrait {
      padding-right: 3rem;

      &--1 {
        width: 34.7%;
      }

      &--2 {
        width: 34.8%;
      }

      &--3 {
        width: 30.5%;
      }
    }

    @include tablet {
      &--1 {
        width: 34.4%;
      }

      &--2 {
        width: 34.4%;
      }

      &--3 {
        width: 31.2%;
      }
    }

    @include laptop {
      &--1 {
        width: 25.5%;
      }

      &--2 {
        width: 25.6%;
      }

      &--3 {
        width: 48.9%;
      }
    }

    // width: 10%;
  }

  &__credit {
    padding: 1rem 0;
  }

  &__label {
  }

  &__value {
    font-weight: 700;
  }

  &__article {
    padding: 2.8rem 0;

    @include portrait {
      padding: 4.6rem 0;
    }

    @include tablet {
      padding: 4.6rem 0 2.3rem;
    }
  }

  &__section {
    @include portrait {
      padding-bottom: 2rem;
      display: flex;
      align-content: flex-start;
      align-items: flex-start;
    }

    @include tablet {
      padding-bottom: 3rem;
    }
  }

  &__media {
    padding-bottom: 2rem;

    @include portrait {
      flex: 1 0 52%;
      padding-right: 3rem;
      max-width: 52%;
    }

    @include laptop {
      padding-right: 3rem;
      flex: 1 0 51%;
      max-width: 51%;
    }

    // img,
    iframe {
      width: 100%;
      display: block;
      min-height: 20rem;
      object-fit: contain;
      object-position: 50% 0%;

      @include portrait {
        min-height: 40rem;
      }

      @include laptop {
        min-height: 50rem;
      }
    }

    @include tablet {
      width: 48.5%;
    }
  }

  &__text {
    @include laptop {
      padding-right: 9%;
    }

    p {
      margin-top: 0;
    }
  }
}
