.intro {
  &__blocks {
    @include portrait {
      margin: 0 -1.5rem;
      display: flex;
      flex-wrap: wrap;
    }
  }

  &__block {
    padding: 2.1rem 0 2rem;

    @include portrait {
      width: calc(33.33% - 3rem);
      margin: 0 1.5rem;
    }
  }

  &__subtitle {
    font-size: 2rem;
    line-height: 1.2;
    padding: 0;
  }

  &__text {
    padding: 1.9rem 0;

    p {
      margin: 0;
    }
  }
}
