.book {
  padding: 0.5rem 0;

  @include portrait {
    padding: 3.4rem 0;
  }

  @include tablet {
    padding: 3.4rem 0 1.2rem;
  }

  &__sections {
    padding: 0.5rem 0 2.2rem;

    @include portrait {
      padding: 0.9rem 0 0.7rem;
    }
  }

  &__section {
    padding: 0.5rem 0;
    position: relative;

    @include portrait {
      padding: 0.5rem 0 0.5rem 26%;
    }

    @include tablet {
      padding: 0.5rem 16% 0.5rem 26%;
    }
  }

  &__media {
    margin: 2.1rem 0;

    @include portrait {
      position: absolute;
      top: -1.1rem;
      left: 0;
      width: 22%;
    }
  }

  &__subtitle {
    font-size: 2rem;
    line-height: 1.2;
    padding: 0.5rem 0 0.6rem;

    @include portrait {
      font-size: 4rem;
      line-height: 1.13;
      padding: 0.5rem 0 2rem;
    }
  }

  &__text {
    padding-bottom: 2.5rem;

    @include portrait {
      padding-bottom: 1.1rem;
    }

    p {
      margin: 0;
    }

    ul {
      li {
        position: relative;

        &:before {
          content: '- ';
        }
      }
    }
  }
}
