h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  line-height: 1.2;
  font-weight: 400;
}

h1,
h2 {
  font-weight: 700;
  font-size: 2rem;
  @include portrait {
    font-size: 4rem;
  }
}

h3 {
  font-size: 2rem;
  @include portrait {
    font-size: 4rem;
  }
}

h4 {
  font-size: 2rem;
}

p {
  margin: 1.5em 0;

  @include portrait {
    margin: 1.5em 0;
  }

  img {
    width: 100%;
  }
}
