.underlink {
	
    position: relative;
    &:after {
      content: '';
      position: absolute;
      bottom: 0.7rem;
      height: 1px;
      background-color: currentColor;
      left: 0;
      right: 0;
      transition: 600ms transform var(--e);
      transform: scale(0, 1);
      transform-origin: 100% 50%;
    }
    @include hover {
      &:after {
        transform: scale(1, 1);
      transform-origin: 0% 50%;
      }
    }

}