.call {
  &__intro {
    font-size: 2rem;
    line-height: 1.2;

    @include portrait {
      padding: 0.6rem 0;
      width: 100%;
    }

    p {
      margin: 0;
    }
  }

  &__dates {
    padding: 2.1rem 0 0.4rem;

    @include portrait {
      padding: 1.6rem 0 0.4rem;
      width: 58.2%;
    }

    @include tablet {
      padding: 2.9rem 0 3.9rem;
      width: 68.7%;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
    }

    @include laptop {
      width: 76.7%;
    }
  }

  &__date {
    position: relative;
    padding: 3.1rem 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;

    @include portrait {
      // padding: 3.3rem 0 3.3rem 8rem;
      padding: 2rem 0;
    }

    @include tablet {
      // padding: 2rem 4rem 2rem 8rem;
      padding: 1.8rem 2rem 1.8rem;
      width: 50%;
    }

    @include laptop {
      width: 33.33%;
    }

    padding: 1.8rem 0;
  }

  &__icon {
    background-repeat: no-repeat;
    background-size: auto 7.1rem;
    background-position: 50% 50%;

    width: 12.6rem;
    height: 8.8rem;
    margin-right: 3rem;

    @include portrait {
      width: 5.6rem;
      height: 7.1rem;
      margin-right: 2.5rem;
    }
  }
  &__info {
    width: calc(100% - 15.6rem);
    @include portrait {
      width: calc(100% - 8.1rem);
    }
  }

  &__label {
  }

  &__value {
    font-size: 2rem;
    line-height: 1.2;
  }

  &__actions {
    @include portrait {
      width: 41.2%;
      padding: 1.4rem 0;
    }

    @include tablet {
      width: 31.3%;
    }

    @include laptop {
      width: 23%;
    }
  }

  &__action {
    margin: 3.4rem 0;
  }
}
