html {
  color: #fff;
  background-color: #000;
  font-size: 10px;
}

body {
  font-family: 'Graphik';
  margin: 0;
  background-color: inherit;
  color: inherit;
  position: relative;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.43;

  ::-webkit-selection {
    color: #000;
    background-color: #fff;
  }

  :-moz-selection {
    color: #000;
    background-color: #fff;
  }

  ::selection {
    color: #000;
    background-color: #fff;
  }

  ::placeholder {
    color: #fff;
    opacity: 1;
    transition: all 350ms var(--e);
  }
}

:focus::placeholder {
  opacity: .3;
}

a {
  text-decoration: none;
  color: currentColor !important;

  &[href^='tel'] {
    color: inherit;
  }
}

table {
  max-width: 100%;

  a {
    text-decoration: underline;
  }
}

tr {
  vertical-align: top;
}

td {
  text-align: inherit;
}

ul {
  margin: 0;
  list-style-type: none;
}

li {
  margin: 0;
}
