.arrow {
  position: absolute;
  top: 1.2rem;
  left: 0.8rem;
  background-color: currentColor;
  width: 4.9rem;
  height: 2px;
  cursor: pointer;
  transition: transform 360ms var(--e), width 360ms var(--e);

  @include tablet {
    top: 2.6rem;
    left: 0.5rem;
  }
  &:before,
  &:after {
    content: '';
    position: absolute;
    left: -0.6rem;
    background-color: currentColor;
    width: 2rem;
    height: 2px;
    transform: rotate(45deg);
    transform-origin: 0% 50%;
  }
  &:before {
    transform: rotate(-45deg) translate3d(-1px, 0, 0);
    top: 0;
  }
  &:after {
    transform: rotate(45deg) translate3d(-1px, 0, 0);
    top: 0;
  }
}
