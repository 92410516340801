.noUi {
  &-target {
    border-radius: 0;
    background-color: #fff;
    box-shadow: none;
    border: none;
  }
  &-horizontal {
    // height: 3.2rem;
    height: 2px;
    .noUi {
      &-handle {
        height: 3.2rem;
        width: 3.2rem;
        right: -1.6rem;
        top: -1.5rem;
      }
      &-tooltip {
        bottom: auto;
        top: 3rem;
      }
    }
  }
  &-tooltip {
    background-color: #000;
    box-shadow: none;
    border: none;
    color: #fff;
    padding: 0;
  }
  &-handle {
    border: none;
    box-shadow: none;
    background-color: transparent;
    // background-color: red;
    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 0.8rem;
      left: 0.8rem;
      width: 1.6rem;
      height: 1.6rem;
      border: solid 1px #fff;
      border-radius: 50%;
      transition: opacity 360ms var(--e);
    }
    &:before {
      z-index: 1;
      background-color: #000;
    }
    &:after {
      z-index: 2;
      background-color: #fff;
    }
    @include hover {
      &:after {
        opacity: 0;
      }
    }

    &-lower {
      text-align: left;
      .noUi {
        &-tooltip {
          left: .8rem;
          transform: none;
        }
      }
    }

    &-upper {
      text-align: right;
      .noUi {
        &-tooltip {
          right: .8rem;
          left: auto;
          transform: none;
        }
      }
    }
  }
}
