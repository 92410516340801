.range {

  &__label {
    display: inline-block;
    padding-right: 4.3rem;
    padding: 1.6rem 4.3rem 1.6rem 0;
    white-space: nowrap;
  }

  &__slider {
    width: 100%;
    @include portrait {
      width: 20.4rem;
    }
    @include tablet {
      width: 28.5rem;
    }
    @include laptop {
      width: 31.7rem;
    }
  }
}
