.nav {
  text-align: right;
  font-size: 0;
  pointer-events: none;

  .nav-open & {
    pointer-events: all;
  }

  &__item {
    position: relative;
    perspective: 800;

    @include hover {
      .nav {
        &__trigger {
          transform: rotateY(-24deg);

          &:after {
            transform: scale(1, 1);
            transform-origin: 100% 50%;
          }
        }
      }
    }
    &.is-open {
      .nav {
        &__trigger {
          transform: rotateY(-24deg);

          &:after {
            transform: scale(1, 1);
            transform-origin: 100% 50%;
          }
        }
        &__subtrigger {
          max-height: 2.9rem;
        }
      }
    }
  }

  &__trigger {
    display: inline-block;
    font-size: 4rem;
    font-weight: 700;
    line-height: 1.25;
    transform: rotateY(81.3deg);
    white-space: nowrap;
    transform-style: preserve-3d;
    transform-origin: 100% 50%;
    opacity: 0;
    transition: 540ms transform var(--e), 1ms opacity var(--e) 540ms;
    position: relative;
    cursor: pointer;
    backface-visibility: hidden;

    .nav-open & {
      transform: rotateY(0deg);
      opacity: 1;
      transition: 540ms transform var(--e), 1ms opacity var(--e);
    }

    &:after {
      height: 2px;
      transform-origin: 0% 50%;
    }
  }

  &__subtrigger {
    font-size: 1.4rem;
    max-height: 0;
    overflow: hidden;
    display: inline-block;
    white-space: nowrap;
    line-height: 2.1;
    transition: 600ms max-height var(--e);

    &:after {
      transform-origin: 0% 50%;
    }

    @include hover {
      &:after {
        transform-origin: 100% 50%;
      }
    }
  }
}
