.jury {
  overflow: hidden;

  &__content {
    @include portrait {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-content: flex-start;
      padding: 1.3rem 0;
    }
  }

  &__actions {
    display: none;

    @include portrait {
      width: 34.4%;
      padding-right: 12.8%;
      padding-top: 2rem;
      display: block;
    }

    @include laptop {
      width: 25.6%;
      padding-right: 10.8%;
    }
  }

  &__text {
    @include portrait {
      width: 49.6%;
    }

    @include tablet {
      width: 60%;
    }
  }

  &__slider {
    overflow: visible;
    position: relative;
    padding: 2.2rem 0 12.4rem;
    margin: 0 -1.5rem;
  }

  &__grid {
    display: flex;
    flex-wrap: wrap;
    margin: 3rem -1.5rem;
  }

  &__slide {
    position: relative;

    @include portrait {
      display: flex;
    }
  }

  &__member {
    padding: 1.5rem;
    position: relative;
    cursor: pointer;
    width: 50%;

    @include portrait {
      width: 25%;
    }

    @include tablet {
      width: 16.6666%;
    }

    &--slide {
      width: 100%;

      @include portrait {
        width: 50%;
      }
    }
  }

  &__media {
    @include flex();
    width: 100%;
    @include tablet {
      height: 24rem;
    }
  }

  &__meta {
    padding: 1.5rem 0;
  }

  &__company {
    font-weight: 700;
  }

  &__link {
    @include absolute();
  }

  &__state {
    position: absolute;
    left: 10.7rem;
    bottom: 9.6rem;
  }

  &__nav {
    position: absolute;
    display: block;
    height: 3rem;
    width: 5.4rem;
    left: 2.1rem;
    bottom: 9rem;
    z-index: 4;
    cursor: pointer;

    &--next {
      left: 17.2rem;
      transform: rotate(180deg);
    }

    &.swiper-button-disabled {
      opacity: 0.2;
      pointer-events: none;
    }

    @include hover {
      .jury {
        &__arrow {
          transform: translate3d(-0.6rem, 0, 0) scale(0.9, 1);
          width: 4.5rem;
        }
      }
    }
  }

  &__arrow {
    top: 1.4rem;
  }
}
