.article {
  &__section {
    padding: 0;
    display: flex;
    justify-content: flex-start;
    margin: 0 -1.5rem;
    width: calc(100% + 3rem);
    align-items: flex-start;
    flex-wrap: wrap;
    @include portrait {
      flex-wrap: nowrap;
    }
    &--left-media-text {
      flex-wrap: wrap;
      @include tablet {
        flex-wrap: nowrap;
      }
      @include laptop {
        padding-right: 9%;
      }
    }
    &--full-media {
      margin: -1.5rem;
    }
    &--left-quote-text {
      flex-wrap: wrap;
      @include tablet {
        flex-wrap: nowrap;
      }
    }
    &--two-media {
    }
    &--text {
    }
  }
  &__media {
    width: 100%;
    padding: 1.2rem 1.5rem;
    @include portrait {
      padding: 2.2rem 1.5rem;
    }
  }
  &__text {
    margin: auto;
    padding: 0rem 1.5rem;
    width: 100%;
    @include portrait {
      width: 83%;
    }
    @include tablet {
      width: 100%;
    }
    &--left,
    &--right {
      @include laptop {
        width: 82%;
      }
    }
    &--full {
      max-width: 66.4rem;
      @include laptop {
        max-width: 72.2rem;
      }
    }
  }
  &__quote {
    width: 100%;
    position: relative;
    align-self: auto;
    margin: auto;
    &--left,
    &--right {
      width: 83%;
    }
    blockquote {
      padding: 2rem 2rem 0;
      margin: 6.6rem auto 7.2rem;
      max-width: 36rem;
      text-align: center;
      display: block;
      font-size: 2rem;
      font-weight: 400;
      line-height: 1.2;
      @include laptop {
        max-width: 48rem;
        line-height: 1.13;
        font-size: 4rem;
      }
    }
    &:before,
    &:after {
      content: '';
      position: absolute;
      left: calc(50% - 2.2rem);
      width: 4.4rem;
      height: 3.8rem;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url('../img/quote.svg');
    }
    &:before {
      top: 2.5rem;
    }
    &:after {
      bottom: 0.7rem;
    }
  }
}
