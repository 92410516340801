.results {
  &__slider {
    margin-bottom: 6.9rem;
    position: relative;

    @include tablet {
      margin-bottom: 5.1rem;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 2px;
      background-color: #fff;
      transform-origin: 50% 0%;
      transform: scale(1, 0);
      transition: 800ms transform var(--e) 120ms;
    }

    &.is-entering {
      &:before {
        transform: scale(1, 1);
      }
    }

    &.has-entered {
      &:before {
        transform: none;
      }
    }
  }

  &__slide {
    position: relative;
    padding-left: 2.3rem;

    @include portrait {
      // border-left: solid 2px #fff;
      padding: 0 2.5rem;
    }
  }

  &__details {
    padding: 0.6rem 0 0;

    &--slide {
      padding-bottom: 2rem;

      @include portrait {
        padding: 0;
        width: 43%;
        height: 25.8rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      @include tablet {
        height: 30rem;
      }

      @include laptop {
        height: 51.4rem;
        width: 34%;
      }
    }
  }

  &__subtitle {
    padding: 1rem 0 0.7rem;
    font-size: 2rem;
    line-height: 1.2;
    position: relative;

    @include laptop {
      font-size: 4rem;
      line-height: 1.13;
    }

    &--slide {
      @include portrait {
        padding: 0;
        top: -0.5rem;
        font-size: 4rem;
        line-height: 1.13;
      }
    }
  }

  &__meta {
    padding: 1.1rem 0 0;
  }

  &__company {
    font-weight: 700;
  }

  &__featured {
    padding-top: 63%;

    @include portrait {
      position: absolute;
      padding-top: 0;
      top: 0;
      right: 0;
      bottom: 0;
      left: 43%;
    }

    @include laptop {
      left: 34%;
    }
  }

  &__link {
    @include absolute();
    z-index: 4;
  }

  &__state {
    cursor: pointer;
    position: absolute;
    width: 8rem;
    text-align: center;
    // bottom: 1.4rem;
    bottom: 0;
    left: calc(50% - 2.5rem);

    @include portrait {
      left: 9rem;
      // bottom: 18rem;
      bottom: 11rem;
    }

    @include laptop {
      bottom: 38.3rem;
    }
  }

  &__nav {
    position: absolute;
    display: block;
    height: 3rem;
    width: 5.4rem;
    left: 3rem;
    bottom: 1rem;
    bottom: 0;
    z-index: 3;
    cursor: pointer;

    @include portrait {
      left: 2.4rem;
      // bottom: 17.4rem;
      bottom: 10.4rem;
    }

    @include laptop {
      // bottom: 47.8rem;
      bottom: 37.8rem;
    }

    &--next {
      left: auto;
      right: 0.6rem;
      transform: rotate(180deg);

      @include portrait {
        right: auto;
        left: 18rem;
      }

    }

    &.swiper-button-disabled {
      opacity: 0.2;
      pointer-events: none;
    }

    @include hover {
      .results {
        &__arrow {
          transform: translate3d(-0.6rem, 0, 0) scale(0.9, 1);
          width: 4.5rem;
        }
      }
    }
  }

  &__arrow {
    top: 1.4rem;
  }

  &__blocks {
    padding: 3.8rem 0 0;

    @include portrait {
      display: flex;
      flex-wrap: wrap;
      margin-right: -3.1rem;
    }

    @include tablet {
      padding: 0.9rem 0 0;
    }
  }

  &__block {
    padding-left: 2.3rem;
    margin-bottom: 5.8rem;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 2px;
      background-color: #fff;
      transform-origin: 50% 0%;
      transform: scale(1, 0);
      transition: 800ms transform var(--e) 120ms;
    }

    @include portrait {
      padding-right: 3.1rem;
      width: 33.33%;
    }

    @include tablet {
      margin-bottom: 2.8rem;
    }

    &.is-entering {
      &:before {
        transform: scale(1, 1);
      }
    }

    &.has-entered {
      &:before {
        transform: none;
      }
    }
  }

  &__media {
    padding-top: 76.7%;
  }

  &__listing {
    @include portrait {
      padding-top: 1.5rem;
    }

    @include laptop {
      padding-top: 3.8rem;
    }
  }
}
