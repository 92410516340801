.contact {
  &__columns {
    @include portrait {
      display: flex;
    }

    @include laptop {
      width: 72%;
    }
  }

  &__column {
    @include portrait {
      width: 50%;
    }
  }

  &__item {
    padding-left: 4rem;
    position: relative;
    padding: 0.5rem 1rem 1.3rem 3.8rem;

    @include portrait {
      font-size: 2rem;
      line-height: 1.2;
      padding: 1rem 1rem 1.3rem 4rem;
    }
  }

  &__icon {
    position: absolute;
    top: 1rem;
    left: 0;
    height: 2rem;
    width: 2rem;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }
  &__newsletter {

  padding: 3.3rem 0 6.7rem;
  width: 29.5rem;
  }
}
