.store {
  &__media {
    padding: 0.6rem 0;

    @include portrait {
      width: 47.9%;
      padding: 3.1rem 0;
    }

    @include tablet {
      width: 42.2%;
    }
  }

  &__content {
    @include portrait {
      padding-left: 3rem;
      width: 52.1%;
    }

    @include tablet {
      width: 56.4%;
    }

    @include laptop {
      width: 35%;
    }
  }

  &__text {
    @include portrait {
      font-size: 2rem;
      line-height: 1.2;
    }

    ul {
      li {
        position: relative;

        &:before {
          content: '- ';
        }
      }
    }

    a {
      text-decoration: underline;
    }
  }

  &__download {
    @include portrait {
      max-width: 21.3rem;
    }
  }
}
