.cookiebar {
  &__content {
    margin: auto;
    max-width: 102.2rem;
    width: 100%;

    padding: 0 2rem;

    @include portrait {
    display: flex;
    align-items: center;
    justify-content: space-between;
      padding: 0 2.7rem;
    }

    @include tablet {
      padding: 0 3.5rem;
    }
  }

  &__text {
    @include portrait {
    width: 45%;

    }
  }
  &__actions {
    position: relative;
    z-index: 1;
    @include portrait {
    display: flex;
    align-items: center;
    margin-right: -1.5rem;
    }
  }
  &__action {
    margin: 1.5em 0;
    flex-basis: 12rem;
    min-width: 12rem;
    @include portrait {
    margin: 1.5em;

    }
    
  }
}
