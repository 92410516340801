.is-ios {
  input[type='text'],
  input[type='number'],
  input[type='email'],
  input[type='password'],
  textarea {
    font-size: 16px;
  }
  .form {
    &__input {
      top: 2px;
    }
  }
}

video::-webkit-media-controls-start-playback-button {
  display: none;
}
