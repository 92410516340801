.tribute {
  &__embed {
    display: block;
    width: 100%;

    img,
    iframe {
      width: 100%;
      min-height: 20rem;
      // object-fit: contain;
      // object-position: 50% 0%;
      @include portrait {
        // min-height: 40rem;
        min-height: 45rem;
      }
      @include laptop {
        min-height: 60rem;
      }
    }
  }

  &__text {
    padding: 2rem 0;
    @include portrait {
      padding: 2.2rem 0 3.4rem 25.8%;
    }
    @include tablet {
      padding: 2.2rem 15% 3.4rem 25.8%;
    }
    @include laptop {
      padding: 2rem 25.5% 5.4rem;
    }
    p {
      margin: 0;
    }
  }
}
