.media {
  position: relative;
  @include flex;
  overflow: hidden;

  &__image {
    display: block;
    width: 100%;
    position: relative;
    object-fit: contain;

    &--card {
      @include absolute;
      object-fit: cover;
      display: block;
      width: 100%;
    }

    &--jury {
      height: 100%;
      object-position: 0% 100%;
    }
  }

  &__background {
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    z-index: 3;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;

    &--contain {
      background-size: contain;
    }

    &--tributes {
      top: 25%;
      right: 10%;
      bottom: 25%;
      left: 10%;
      background-size: contain;
    }
  }
}
