.star {
  transition: transform 800ms var(--e);
  &__media {
    @include absolute;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50% 50%;
    &--banner {
      animation: rotate 40s linear infinite forwards;
      background-image: url('../img/star__banner.svg');
      z-index: 1;
    }
    &--slogan {
      z-index: 2;
      background-image: url('../img/star__slogan.svg');
      transition: transform 650ms var(--e) 50ms;
    }
  }
  @include hover {
    transform: scale(1.06);
    .star {
      &__media {
        &--slogan {
          transform: scale(0.9);
        }
      }
    }
  }
}
