.press {
  padding-bottom: 5.6rem;

  @include portrait {
    padding: 3.8rem 0 5.6rem;
  }

  @include tablet {
    display: flex;
    flex-wrap: wrap;
  }

  &__embed {
    img,
    iframe {
      width: 100%;
      min-height: 20rem;
      object-fit: contain;
      object-position: 50% 0%;
      @include portrait {
        min-height: 40rem;
      }
      @include laptop {
        min-height: 50rem;
      }
    }

    @include tablet {
      width: 48.5%;
    }
  }

  &__content {
    @include portrait {
      padding: 0.3rem 25% 1rem 17.5%;
      position: relative;
    }

    @include tablet {
      width: 51.5%;
      padding: 0.3rem 0rem 1rem 3rem;
    }
  }

  &__icon {
    display: block;
    width: 8rem;
    height: 8rem;
    margin: 2.6rem auto;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50% 50%;

    @include portrait {
      position: absolute;
      height: 8.7rem;
      left: 0;
      margin: 0;
      top: 2.4rem;
      width: 9.3rem;
      background-position: 0% 0%;
    }

    @include tablet {
      position: relative;
      top: auto;
    }
  }

  &__more {
    margin: 2.8rem 0;

    @include portrait {
      position: absolute;
      right: 0;
      width: 15.6rem;
      top: -0.4rem;
    }

    @include tablet {
      position: relative;
      top: auto;
      right: auto;
    }
  }
}
