.back {
  line-height: 1.2;
  display: inline-block;
  min-width: 70%;
  position: relative;
  padding-left: 7.7rem;
  transition: padding 360ms var(--e);
  padding-bottom: 2.5rem;
  padding-top: 1rem;

  @include portrait {
    padding: 0.4rem 0 1.7rem 7.4rem;
  }

  &__arrow {
    position: absolute;
    top: 2.1rem;
    left: 0.8rem;

    @include portrait {
      top: 2.6rem;
      left: 0.5rem;
    }
  }

  &__label {
    font-size: 2rem;

    @include portrait {
      font-size: 4rem;
    }
  }

  @include hover {
    padding-left: 7rem;

    .back {
      &__arrow {
        transform: translate3d(-0.6rem, 0, 0) scale(0.9, 1);
        width: 4.5rem;
      }
    }
  }
}
