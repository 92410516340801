.pricing {
  &__blocks {
    @include portrait {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -1.5rem;
    }
  }
  &__block {
    @include portrait {
      width: calc(50%-3rem);
      margin: 0 1.5rem;
    }
    @include tablet {
      width: calc(33.33%-3rem);
    }
    @include laptop {
      width: calc(25%-3rem);
    }
  }
}
