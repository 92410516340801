.app {
  &__header {
    z-index: 6;
  }
  &__heading {
    z-index: 5;
    overflow: visible;
    &:after {
      top: -17rem;
    }
  }
  &__filters {
    z-index: 6;
  }
  &__expands {
    position: relative;
    z-index: 3;
  }
  &__slogan {
    transform: translate3d(-8rem, 0, 0);
  }
  &__star {
    transition: transform 1.6s var(--easeInOutBack) 0.2s, opacity 0.8s var(--e) 0.2s;
    transform: scale(0.5);
  }
  &__dropdown {
    z-index: 2;
    position: relative;
  }
  &.top-sticky {
    .app {
      &__mask {
        opacity: 1;
      }
    }
  }
}

.expand {
  &__top {
  }
  &__title {
    // transition: background-position 2760ms, transform 0.24s var(--e), padding 0.36s var(--e);
    // background-clip: text;
    // color: transparent;
    // background-image: linear-gradient(
    //   to right,
    //   rgba(255, 255, 255, 1) 50%,
    //   rgba(255, 255, 255, 0) 50%
    // );
    // background-size: 200% 100%;
    // background-position: 100% 50%;
    // &.is-entering {
    //   background-position: 0% 50%;
    // }
    // &.has-entered {
    //   background-image: none;
    //   color: #fff;
    // }
  }
}

.header {
  &__logo {
    transition: transform 1s var(--e), opacity 1s var(--e);
    .preload & {
      opacity: 0;
      transform: translate3d(0, -4rem, 0);
    }
  }
  &__navicon {
    transition: transform 1s var(--e), opacity 1s var(--e);
    .preload & {
      opacity: 0;
      transform: translate3d(4rem, 0, 0);
    }
  }
}

.btn {
  &__select {
    @include absolute;
    display: block;
    opacity: 0;
    @include tablet {
      display: none;
    }
  }
  &--dropdown {
    .btn {
      &__text {
        // display: none;
        // opacity: 0;
        @include tablet {
          // display: block;
          // opacity: 1;
        }
      }
    }
  }
}
