.share {
  display: flex;
  white-space: nowrap;

  &__icons {
    display: flex;
  }

  &__icon {
    margin-left: 2rem;
    position: relative;
    transform-origin: 50% 50%;
    transition: transform 360ms var(--e), opacity 360ms var(--e);

    @include hover {
      transform: scale(0.98);
      opacity: 0.8;
    }
  }

  &__image {
    width: 2.2rem;
    height: 2.2rem;
    object-fit: contain;
  }

  &__link {
    @include absolute;
  }
}