.testimonials {
  &__grid {
    @include portrait {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -1.5rem;
    }
  }

  &__item {
    position: relative;
    padding: 4.3rem 0;

    @include portrait {
      width: 33.33%;
      padding: 5rem 1.5rem;
    }

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: -0.2rem;
      width: 3.6rem;
      height: 3.2rem;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url('../img/quote.svg');

      @include portrait {
        left: 1.3rem;
        top: 0;
      }
    }
  }

  &__quote {
    padding: 0.7rem 0;
    font-size: 2rem;
    line-height: 1.2;

    @include portrait {
      padding: 1.4rem 0;
      display: flex;
      align-items: center;
      border-top: solid 2px #fff;
      border-bottom: solid 2px #fff;
    }

    p {
      margin: 0;
    }
  }

  &__author {
    @include portrait {
      padding: 1.5rem 0;
    }
  }
}
