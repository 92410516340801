.expand {
  &__top {
    padding: 2rem 7.8rem 2.3rem 0;
    cursor: pointer;
    transition: padding 360ms var(--e);
    position: relative;
    z-index: 4;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 2px;
      background-color: #fff;
      z-index: 2;
      // transition: transform 1760ms var(--easeOutQuad) 300ms;
      transition: transform 1760ms linear 300ms;
      // transition: transform 1760ms var(--e) 300ms;
      will-change: transform;
      transform-origin: 0% 50%;
      transform: scale(0, 1);
    }
    @include portrait {
      padding: 1.8rem 7.8rem 2.4rem 0;
    }
    @include hover {
      .expand {
        &__title {
          transform: translate3d(0.4rem, 0, 0);
        }
      }
    }
  }

  &__title {
    pointer-events: none;
    transition: background-position 2760ms, transform 0.24s var(--e), padding 0.36s var(--e);
    background-clip: text;
    color: transparent;
    background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 1) 50%,
      rgba(255, 255, 255, 0) 50%
    );
    background-size: 200% 100%;
    background-position: 100% 50%;
  }

  &__icon {
    pointer-events: none;
    position: absolute;
    width: 3rem;
    height: 3rem;
    right: 0rem;
    top: 1.8rem;
    opacity: 0;
    transform: translate3d(-5rem, 0, 0);
    transition: opacity 600ms var(--e) 1500ms, transform 600ms var(--e) 1500ms;
    @include portrait {
      top: 3rem;
    }

    &:before,
    &:after {
      transition: transform 360ms var(--e);
      content: '';
      background-color: #fff;
      width: 3rem;
      height: 2px;
      position: absolute;
      left: 0;
      top: 1.4rem;
    }
    &:before {
      transform: rotate(-180deg);
    }

    &:after {
      transform: rotate(-90deg);
    }
  }

  &__content {
    // display: none;
    height: 0;
    overflow: hidden;

    // transition: transform 1760ms var(--e) 300ms, opacity 1760ms var(--e) 300ms;
    // opacity: 0;
    // transform: translate3d(0, 4rem, 0);
  }

  &__who {
    padding: 0.1rem 0 5.5rem 0;

    @include portrait {
      padding: 0.6rem 0 5.5rem 0;
    }

    @include tablet {
      padding: 0.6rem 0 5.5rem 25.6%;
    }
  }

  &__testimonials {
    padding: 2.1rem 0 1.5rem;

    @include portrait {
      padding: 2.8rem 0 2.7rem;
    }

    @include tablet {
      padding: 2.8rem 0 2.7rem 25.6%;
    }
  }

  &__store {
    padding: 0 0 5.6rem 0;

    @include portrait {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      padding: 0 0 5.6rem 0;
    }

    @include tablet {
      padding: 0.2rem 0 5.6rem 25.6%;
    }
  }

  &__contact {
    padding: 0 0 2.2rem 0;

    @include portrait {
      padding: 1.9rem 0 7.4rem 0;
    }

    @include tablet {
      padding: 1.9rem 5.3rem 2.7rem 25.6%;
    }
  }

  &__call {
    padding: 0 0 3rem 0;

    @include portrait {
      padding: 0 0 4.6rem 0;
      display: flex;
      flex-wrap: wrap;
    }
  }

  &__categories {
    padding: 3.4rem 0;
    position: relative;

    @include portrait {
      padding: 2.4rem 0 8rem;
      // flex-wrap: wrap;
      // display: flex;
    }
  }

  &__rules {
    @include portrait {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -1.5rem;
    }
  }

  &__pricing {
    padding-bottom: 2rem;
  }

  &:last-child {
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 2px;
      background-color: #fff;
      z-index: 2;
      // transition: transform 1760ms var(--easeInOutQuad) 1200ms;
      transition: transform 1760ms var(--e) 1200ms;
      will-change: transform;
      transform-origin: 0% 50%;
      transform: scale(0, 1);
    }
  }

  &.is-entering {
    &:after {
      transform: scale(1, 1);
    }
    .expand {
      &__title {
        background-position: 0% 50%;
      }
      &__top {
        &:before {
          transform: scale(1, 1);
          background-position: 0% 50%;
        }
      }
      &__icon {
        opacity: 1;
        transform: translate3d(0, 0, 0);
      }
      &__content {
        opacity: 1;
        transform: translate3d(0, 0, 0);
      }
    }
  }
  &.has-entered {
    &:after {
      // transform: scale(1, 1);
      transform: none;
    }
    .expand {
      &__title {
        background-position: 0% 50%;
      }
      &__icon {
        opacity: 1;
        transform: none;
      }
      // &__content {
      //   opacity: 1;
      //   transform: none;
      // }
      &__top {
        &:before {
          transform: none;
        }
      }
    }
  }
  &.slidedown-start,
  &.is-open {
    .expand {
      &__top {
        padding-right: 4rem;
      }
      &__icon {
        &:before,
        &:after {
          transform: rotate(0deg);
        }
      }
      &__content {
        height: auto;

        &--text {
          padding: 0rem 0 1.5rem;
          @include portrait {
            padding: 3.3rem 0 5.6rem;
          }
        }
      }
    }
    .title {
      padding-left: 2.7rem;
      @include portrait {
        padding-left: 3.8rem;
      }

      &__no {
        transform: scale(1, 1);
      }
    }
  }
  &.is-open {
    .expand {
      &__content {
        overflow: visible;
      }
    }
  }
  &.slidedown-start {
    .expand {
      &__content {
        overflow: hidden;
      }
    }
  }
  &.slideup-start {
    .expand {
      &__title {
        padding-right: 4rem;
      }
      &__icon {
        &:before {
          transform: rotate(-180deg);
        }

        &:after {
          transform: rotate(-90deg);
        }
      }
    }
    .title {
      padding-left: 0;
      &__no {
        transform: scale(0, 1);
      }
    }
  }
}
