.download {
  display: block;
  width: 2.8rem;
  // padding-top: 100%;
  background: red;
  background-color: transparent;
  &:before,
  &:after {
    content: '';
    position: absolute;
    transition: transform 360ms var(--e);
  }
  &:after {
    height: 2px;
    background-color: currentColor;
    width: 100%;
    top: 80%;
    left: 0;
  }
  &:before {
    top: -16%;
    left: 17%;
    width: 65%;
    height: 65%;
    transform: rotate(45deg);
    border-bottom: solid 2px currentColor;
    border-right: solid 2px currentColor;
  }
}
