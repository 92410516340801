.meta {
  position: relative;
  &__category {
    line-height: 1.2;
    font-size: 2rem;
    padding: 0rem 0 1rem;

    @include portrait {
      padding: 0rem 3rem 0 0;
    }
  }
  &__award {
    position: absolute;
    font-size: 2rem;
    line-height: 1.2;
    right: 0;
    font-weight: 700;
    bottom: 2.6rem;
    padding-left: 3rem;
    background-size: 0 0;

    @include portrait {
      position: relative;
      bottom: auto;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      width: 1.7rem;
      height: 2.6rem;
      background-image: inherit;
      left: 0.6rem;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: 50% 50%;
    }
  }
}
