.coverage {
  padding: 2.6rem 0 0;
  border-bottom: solid 2px #fff;

  @include tablet {
    padding: 7.3rem 0 0;
  }

  @include laptop {
    padding: 3.7rem 0 0;
  }

  &__row {
    position: relative;
    padding: 1.6rem 0 1.4rem;
    border-top: solid 2px #fff;

    @include portrait {
      display: flex;
      flex-wrap: wrap;
    }
  }

  &__meta {
    padding: 1rem 10rem 1rem 0;

    @include portrait {
      width: 26%;
      padding-right: 0;
    }

    @include tablet {
      width: 43%;
      display: flex;
      flex-wrap: wrap;
    }

    @include laptop {
      width: 34%;
    }
  }

  &__country {
    font-weight: 700;
    padding-right: 2rem;

    @include tablet {
      width: 40%;
    }

    @include laptop {
      width: 50%;
    }
  }

  &__name {
    padding-right: 2rem;

    @include tablet {
      width: 60%;
    }

    @include laptop {
      width: 50%;
    }
  }

  &__heading {
    padding: 1rem 0;

    @include portrait {
      width: 74%;
    }

    @include tablet {
      padding-right: 12rem;

      width: 57%;
    }
  }

  &__date {
    position: absolute;
    top: 2.4rem;
    right: 0rem;
  }

  &__link {
    @include absolute();
  }
}
