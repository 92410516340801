.rules {
  &__item {
    position: relative;
    margin: 2.4rem 0 5.3rem;
    border: solid 1px #fff;
    border-top: none;

    @include portrait {
      width: calc(50%-3rem);
      margin: 2.4rem 1.5rem 5.3rem;
    }

    @include tablet {
      width: calc(33.33%-3rem);
    }

    @include laptop {
      width: calc(25%-3rem);
    }
  }

  &__no {
    position: absolute;
    font-size: 4rem;
    height: 4rem;
    line-height: 1;
    text-align: center;
    font-style: normal;
    left: 0;
    right: 0;
    top: -2rem;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 2rem;
      width: calc(50%-4.1rem);
      height: 1px;
      background-color: #fff;
    }

    &:before {
      left: 0;
    }

    &:after {
      right: 0;
    }
  }

  &__text {
    padding: 9.6rem 3.1rem;
    text-align: center;
    height: 100%;
    display: flex;
    align-items: center;

    p {
      margin: 0;
    }
  }
}
