.modal {
  opacity: 0;
  display: none;
  transition: opacity 360ms var(--e), height 1ms linear 999s;
  position: fixed;
  -webkit-overflow-scrolling: touch;
  pointer-events: none;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 9rem 2rem;
  align-items: center;
  display: none;

  @include portrait {
    flex-wrap: wrap;
    margin: auto;
  }

  &.is-entering {
    display: block;

    @include portrait {
      display: flex;
    }
  }

  &.is-active {
    opacity: 1;
    pointer-events: all;
    display: block;

    @include portrait {
      display: flex;
    }
  }

  &.is-leaving {
    opacity: 0;
  }

  &__backdrop {
    z-index: 1;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.8);
  }

  &__dialog {
    position: relative;
    // overflow: hidden;
    margin: auto;
    z-index: 2;
    width: 100%;
    max-width: 62.8rem;
    border: solid 2px #fff;
    background-color: #000;
    height: auto;
    display: block;
  }

  &__close {
    cursor: pointer;
    position: absolute;
    width: 7rem;
    height: 7rem;
    top: 0;
    right: 0;

    &:before,
    &:after {
      content: '';
      position: absolute;
      width: 3rem;
      height: 2px;
      background-color: #fff;
      right: 2rem;
      top: 3.4rem;
      z-index: 1;
      transition: transform 400ms var(--e);
      transform-origin: 50% 50%;
    }
    &:before {
      transform: rotate(-45deg);
    }
    &:after {
      transform: rotate(45deg);
    }
    @include hover {
      &:before {
        transform: rotate(45deg);
      }

      &:after {
        transform: rotate(135deg);
      }
    }
  }

  &__media {
    margin: 1.6rem 0;
    @include portrait {
      position: absolute;
      top: 2.8rem;
      left: 3rem;
      width: 13.5rem;
      height: 18rem;
      margin: 0;
      object-fit: contain;
      object-position: 0% 0%;
    }
  }
  &__content {
    padding: 7rem 2.9rem 3rem;
    @include portrait {
      padding: 1.7rem 2.9rem;
    }
  }
  &__info {
    @include portrait {
      padding-left: 16.4rem;
    }
  }
  &__title {
    font-size: 4rem;
  }
  &__meta {
    font-weight: 700;
  }
  &__text {
    min-height: 10.2rem;
  }
  &__actions {
    padding: 2rem 0 0;
    @include portrait {
      padding: 0;
    }
  }
  &__action {
    margin-bottom: 2rem;
  }
}
