.app {
  padding-top: 9.7rem;

  @include portrait {
    padding-top: 23.1rem;
  }

  &__header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 3;
    pointer-events: none;
    transition: min-height 320ms var(--e);
    min-height: 9rem;

    &:after {
      content: '';
      position: absolute;
      transition: opacity 200ms var(--e);
      top: -1.2rem;
      bottom: -2rem;
      pointer-events: none;
      left: -2rem;
      right: -2rem;
      opacity: 0;
      top: -17rem;
      z-index: -1;
      background-image: linear-gradient(to top, rgba(0, 0, 0, 0) 0rem, rgba(0, 0, 0, 1) 10rem);
    }

    @include portrait {
      min-height: 11.3rem;
    }
  }

  &__visual {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 0;
    bottom: 0;
    @include flex();
  }

  &__container {
    z-index: 2;
    margin: auto;
    width: 100%;
    max-width: 148rem;
    padding: 0 2rem;
    position: relative;
    transition: transform 1s var(--e), opacity 1s var(--e);
    will-change: transform, opacity;

    &--default {
      max-width: 83rem;
    }

    @include portrait {
      padding: 0 2.7rem;
    }

    @include tablet {
      padding: 0 3.5rem;
    }
  }

  &__hero {
    position: relative;
    min-height: calc(100vh - 15rem);

    @include portrait {
      min-height: calc(100vh - 23.1rem);
    }
  }

  &__star {
    position: absolute;
    bottom: 17vh;
    left: 0;
    width: 15rem;
    height: 15rem;
    display: block;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50% 50%;

    @include portrait {
      bottom: 10.4rem;
      left: 8.8%;
    }

    @include tablet {
      bottom: 16.2rem;
      left: 12.1%;
    }

    @include laptop {
      bottom: 36.3vh;
      left: 10.9%;
    }
  }

  &__scrolldown {
    position: absolute;
    bottom: 4rem;
    width: 3rem;
    cursor: pointer;
    z-index: 4;
    height: 5.9rem;
    animation: none;
    right: 3rem;

    .arrow {
      left: -0.9rem;
      transform: rotate(-90deg);
      transition: transform 1s var(--e) 1s, background-color 1s var(--e) 1s;
    }

    &.is-entering,
    &.has-entered {
      animation: hover 3s var(--e) infinite forwards;

      .arrow {
        background-color: transparent;
      }
    }

    @include portrait {
      bottom: 5rem;
      left: calc(50% - 1.5rem);
      right: auto;
    }
  }

  &__slogan {
    position: absolute;
    bottom: 2rem;
    left: 0;
    max-width: 14rem;
    z-index: 3;

    @include tablet {
      max-width: 22rem;
      font-size: 2rem;
      line-height: 1.3333;
    }
  }

  &__badges {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    align-content: center;
    padding: 1.9rem 0;

    @include portrait {
      justify-content: space-between;
      padding: 2.3rem 0 1rem;
    }
  }

  &__badge {
    max-width: 40%;
    width: 50%;
    object-fit: contain;
    object-position: 50% 50%;
    max-height: 11.5rem;
    margin: 2rem 0.7rem;

    @include portrait {
      // width: auto;
      margin: 2rem 0;
      max-width: 16.4%;
      max-height: 13.2rem;
    }

    @include tablet {
      max-height: 15.4rem;
      margin: 1rem 0 2rem;
    }

    @include laptop {
      margin: 1.4rem 0 6.7rem;
      max-height: 20.6rem;
    }
  }

  &__cards {
    display: flex;
    flex-wrap: wrap;

    @include portrait {
      margin-right: -2.9rem;
    }
  }

  &__card {
    &.is-clone {
      display: none;
    }
  }

  &__top {
    position: relative;
  }

  &__heading {
    position: relative;
    z-index: 2;
    display: flex;
    width: 100%;
    align-items: center;

    &.title {
      padding-left: 3.8rem;
    }

    .title {
      &__no {
        transform: scale(1, 1);
      }
    }
  }

  &__picker {
    position: relative;
    z-index: 5;
    margin-top: 3.2rem;
  }

  &__abstract {
    padding: 1rem 0;

    @include portrait {
      font-size: 2rem;
      line-height: 1.2;
      padding: 1.9rem 45% 0.8rem 0;
    }

    @include tablet {
      line-height: 1.13;
      font-size: 4rem;
    }
  }

  &__mask {
    display: block;
    position: fixed;
    pointer-events: none;
    top: 0;
    left: 0;
    right: 0;
    height: 22rem;
    z-index: 4;
    background-color: #000;
    opacity: 0;
  }

  &__overlay {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    z-index: 5;
    opacity: 0;
    background-color: #000;
    transition: opacity 320ms var(--e);
  }

  &__more {
    position: relative;
    z-index: 3;

    @include portrait {
      width: calc(100% - 2.9rem);
    }

    &:before,
    &:after {
      pointer-events: none;
    }

    &.is-hidden {
      display: none;
    }
  }

  &__meta {
    padding: 1.1rem 0;
    display: block;

    @include portrait {
      padding: 0.7rem 0;
    }

    &--award {
      border-bottom: solid 1px #fff;
      padding-bottom: 1.7rem;

      @include portrait {
        border-bottom: none;
        display: flex;
        flex-wrap: wrap;
        padding: 3.6rem 0 2.4rem;
      }
    }
  }

  &__share {
    padding: 1.6rem 0 0.5rem;
    position: relative;
    z-index: 6;

    @include portrait {
      float: right;
    }

    &--top {
      @include portrait {
        margin-top: -4.6rem;
      }
    }

    &--award-top {
      margin: 1.5rem 0;

      @include portrait {
        margin-top: -6.6rem;
        // padding: 3.1rem 0 2rem;
      }
    }
  }

  &__expands {
    padding: 5.8rem 0 0rem;
    // border-bottom: solid 2px #fff;

    @include portrait {
      padding: 3.5rem 0 0;
    }
  }

  &__bottom {
    // display: flex;
    // flex-wrap: wrap;
    position: relative;
    padding: 1.4rem 0;
  }

  &__subtitle {
    padding: 4.5rem 0 1.6rem;
  }

  &__open {
    display: none;
    position: absolute;
    right: 0.4rem;
    bottom: -1.2rem;

    @include portrait {
      display: flex;
    }
  }

  &__filters {
    display: flex;
    position: relative;
    width: 100%;

    @include portrait {
      padding: 1.1rem 0 3.3rem 0;
    }

    &--material {
      flex-wrap: wrap;

      @include portrait {
        padding-right: 32rem;
      }

      @include tablet {
        padding-right: 47rem;
      }
    }
  }

  &__filter {
    margin-right: 3rem;
    margin-bottom: 3rem;
    width: 9rem;
    text-align: center;
    display: none;

    @include portrait {
      padding: 0.8rem 1rem;
      display: flex;
    }
  }

  &__mob-filter {
    margin: 0.1rem 0 2.4rem;
    position: relative;
    z-index: 7;

    @include portrait {
      display: none;
    }
  }

  &__title {
    @include portrait {
      display: flex;
      flex-wrap: wrap;
    }
  }

  &__dropdowns {
    border-bottom: solid 2px #fff;
    padding: 1.5rem 0 2rem;
    position: relative;
    z-index: 5;

    @include portrait {
      display: flex;
      flex-wrap: wrap;
      padding: 2rem 0 1.7rem;
    }
  }

  &__dropdown {
    margin: 1.4rem 0;

    // display: inline-block;
    @include portrait {
      width: auto;
      margin: 1.7rem 3.4rem 1.7rem 0;
    }
  }

  &__tags {
    display: flex;
    flex-wrap: wrap;
    padding: 1.5rem 0;
    border-bottom: solid 2px #fff;

    @include portrait {
      border-bottom: none;
      padding: 1.5rem 0 0rem;
    }
  }

  &__tag {
    padding: 0.5rem 0.9rem 0.4rem 2.7rem;
    position: relative;
    border: solid 1px #fff;
    color: #fff;
    text-transform: uppercase;
    margin: 1.5rem 3.1rem 1.5rem 0;
    cursor: pointer;
    transition: background-color 220ms var(--e);
    background-color: rgba(255, 255, 255, 0);

    @include hover {
      background-color: rgba(255, 255, 255, 0.3);
    }

    &:active {
      color: #000;
      background-color: rgba(255, 255, 255, 1);
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      width: 1rem;
      height: 1px;
      left: 0.8rem;
      top: 1.4rem;
      background-color: currentColor;
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }
  }

  &__countries {
    position: relative;

    &--mobile {
      margin-bottom: 3.5rem;

      @include portrait {
        display: none;
      }
    }

    &--inline {
      display: none;
      width: 16rem;
      margin-left: 6rem;

      @include portrait {
        display: flex;
      }
    }
  }

  &__title {
    &--award {
      padding: 0.9rem 0 0;

      @include portrait {
        padding: 0 25% 0 0;
      }
    }
  }

  &__notfound {
    min-height: 30vh;
  }

  &__results {
    padding: 0rem 0;
    position: relative;
    z-index: 1;

    @include portrait {
      padding: 4.9rem 0;
    }
  }

  &__back {
  }

  &__default {
  }

  &__range {
    margin: 0 0 5rem;
    width: 100%;

    @include portrait {
      width: auto;
      position: absolute;
      top: 2.5rem;
      right: 1rem;

      display: flex;
      align-items: center;
    }
  }

  &__modal {
    z-index: 7;
  }

  &.has-cookiebar {
    .app {
      &__cookiebar {
        // padding: 2rem 0;
        // height: auto;
      }
    }
  }

  &.header-fixed {
    .header {
      &:after {
        opacity: 1;
      }
    }
  }

  &.nav-open {
    .app {
      &__overlay {
        opacity: 0.8;
        pointer-events: all;
      }
    }
  }

  // &.video-suspended {
  //   .visual {
  //     &__video {
  //       opacity: 0;
  //     }
  //   }
  // }
}
