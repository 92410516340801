@mixin portrait() {
  @media only screen and (min-width: 768px) {
    @content;
  }
}

@mixin tablet() {
  @media only screen and (min-width: 1024px) {
    @content;
  }
}

@mixin laptop() {
  @media only screen and (min-width: 1280px) {
    @content;
  }
}

@mixin hover() {
  @media (hover: hover) {
    &:hover {
      @content;
    }
  }
}
