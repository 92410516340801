.open {
  align-content: center;
  align-items: center;

  &__label {
    font-size: 2rem;
    display: inline-block;
    white-space: nowrap;
    padding: 1.8rem 3rem;
  }

  &__action {
    width: 20.6rem;
  }
}
