.header {
  &__cookiebar {
    position: relative;
    padding: 1rem 0 0.9rem;
    pointer-events: all;
    display: none;
    background-color: #000;
    width: 100%;
    border-bottom: solid 2px #fff;
  }

  &__container {
    min-height: inherit;
    margin: auto;
    max-width: 148rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    position: relative;
    padding: 0 2rem;

    @include portrait {
      padding: 0 2.7rem;
    }

    @include tablet {
      padding: 0 3.5rem;
    }
  }

  &__logo {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 0 0;
    display: block;
    width: 12.5rem;
    height: 4.3rem;
    pointer-events: all;

    @include portrait {
      height: 4.7rem;
      width: 13.6rem;
    }
  }

  &__navicon {
    position: relative;
    width: 7rem;
    height: 7rem;
    pointer-events: all;
  }

  &__nav {
    position: fixed;
    top: 8.4rem;
    right: 3.7rem;
    left: 3.7rem;
    bottom: 0;
    padding-bottom: 5rem;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    @include tablet {
      position: absolute;
      overflow-x: hidden;
      overflow-y: hidden;
      top: 8.4rem;
      right: 3.7rem;
      padding-bottom: 0;
      left: auto;
      bottom: auto;
    }
  }
}
