.navicon {
  cursor: pointer;
  &__bar {
    position: absolute;
    width: 3rem;
    height: 2px;
    background-color: #fff;
    right: 0rem;
    top: 3rem;
    z-index: 1;
    transition: transform 400ms var(--e);
    transform-origin: 50% 50%;
    &--top {
      transform: translate3d(0, -1.1rem, 0);
      .nav-open & {
        transform: rotate(-45deg);
        @include tablet {
          animation: foldTop 800ms 1;
        }
      }
    }

    &--mid {
      .nav-open & {
        transform: scale(1, 0);
        transform-origin: 50% 50%;
      }
    }

    &--bottom {
      transform: translate3d(0, 1.1rem, 0);

      .nav-open & {
        transform-origin: 50% 50%;
        transform: rotate(45deg);
        @include tablet {
          animation: foldBottom 800ms 1;
        }
      }
    }
  }
  @include hover {
    .navicon {
      &__bar {
        &--mid {
          transform: scale(0.8, 1);
          .nav-open & {
            transform: scale(1, 0);
          }
        }
      }
    }
  }
}

@keyframes foldTop {
  0% {
    transform: translate3d(0, -1.1rem, 0);
  }

  50% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: rotate(-45deg);
  }
}

@keyframes foldBottom {
  0% {
    transform: translate3d(0, 1.1rem, 0);
  }

  50% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: rotate(45deg);
  }
}
