.btn {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  cursor: pointer;
  color: #fff;
  position: relative;
  text-decoration: none;
  font-weight: 700;
  width: 100%;
  line-height: 1.14;
  min-height: 5rem;
  padding: 1rem 1.6rem;

  &:before {
    @include absolute;
    content: '';
    z-index: 3;
    background-color: #000;
    opacity: 1;
    border: solid 2px #fff;
    transition: background-color 360ms var(--e);
  }

  &:after {
    content: '';
    @include absolute;
    transition: transform 360ms var(--e), border-width 360ms var(--e);
    z-index: 2;
    border: solid 2px currentColor;
    background-color: #000;
    transform: translate3d(0.4rem, 0.4rem, 0);
    pointer-events: none;
  }

  path,
  polygon {
    transition: fill 360ms var(--e);
    fill: currentColor;
    position: relative;
    z-index: 4;
  }

  &__select {
    z-index: 5;
    width: 100%;
    height: 100%;
  }

  &__text {
    position: relative;
    z-index: 4;
    transition: color 360ms var(--e);
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    position: relative;
    z-index: 3;
    width: 1.6rem;
    text-align: center;
    color: currentColor;
    transition: color 360ms var(--e), transform 360ms var(--e);

    &--s {
      width: 1rem;
    }

    &--l {
      width: 2.4rem;
    }

    &--left {
      margin-right: 1rem;

      @include laptop {
        margin-right: 2rem;
      }
    }

    &--right {
      margin-left: 1rem;

      @include laptop {
        margin-left: 2rem;
      }
    }

    svg {
      width: 100%;
      height: 100%;
      display: block;
      margin: auto;
    }
  }

  &__download {
    width: 2.4rem;
    height: 2.4rem;
  }

  &__dropdown {
    left: 0;
    transform: translate3d(0.4rem, 0.4rem, 0);
    z-index: 1;
  }

  &__arrow {
    transform: rotate(180deg);
    top: 2.3rem;
    left: 1.3rem;
    z-index: 4;
  }

  @include hover {
    text-decoration: none;
    z-index: 3;

    &:before {
      background-color: #fff;
    }

    &:after {
      transform: translate3d(0.9rem, 0.9rem, 0);
    }

    .btn {
      &__text,
      &__icon {
        color: #000;
      }

      &__dropdown {
        transform: translate3d(0.9rem, 0.9rem, 0);
        width: 24.4rem;
        max-height: 21rem;
        padding: 1rem 0;
        &--fit {
          width: calc(100% + 0.9rem);
        }
      }

      &__download {
        &:before {
          transform: translate3d(0, -0.1rem, 0) rotate(45deg);
        }

        &:after {
          transform: translate3d(0, 0.2rem, 0);
        }
      }
    }
  }

  &.is-active {
    &:after {
      transform: translate3d(1.4rem, 1.4rem, 0);
    }
  }

  &--dropdown {
    @include hover {
      &:after {
        border-bottom-width: 0;
      }
      .btn {
        &__icon {
          transform: rotate(-180deg);
        }
      }
    }
    &:focus {
      z-index: 3;

      &:after {
        border-bottom-width: 0;
        transform: translate3d(0.9rem, 0.9rem, 0);
      }

      &:before {
        background-color: #fff;
      }

      .btn {
        &__text,
        &__icon {
          color: #000;
        }

        &__dropdown {
          transform: translate3d(0.9rem, 0.9rem, 0);
          max-height: 21rem;
          padding: 1rem 0;
          width: calc(100% + 0.9rem);

          @include portrait {
            width: 24.4rem;
          }
        }
      }
    }
  }

  &--red {
    .btn {
      &__text,
      &__icon {
        color: #1FA2A5;
      }
    }
    &:after {
      border-color: #1FA2A5;
    }

    &:before {
      border-color: #1FA2A5;
    }

    @include hover {
      &:before {
        background-color: #1FA2A5;
      }
    }
  }

  &.is-collapsed {
    &:before {
      background-color: #000;
    }

    &:after {
      transform: translate3d(0.4rem, 0.4rem, 0);
    }

    .btn {
      &__text,
      &__icon {
        color: #fff;
      }

      &__dropdown {
        max-height: 0;
        transform: translate3d(0, 0, 0);
        padding: 0;
        &--fit {
          width: calc(100% + 0.9rem);
        }
      }
    }
  }
}

.dropdown {
  position: absolute;
  top: calc(100% - 2px);
  width: 100%;
  font-weight: 400;
  color: #fff;
  max-height: 0;
  overflow: hidden;
  transition: transform 360ms var(--e), max-height 360ms var(--e), padding 360ms var(--e),
    width 360ms var(--e);

  &:after {
    content: '';
    @include absolute;
    right: 0.9rem;
    z-index: -2;
    border: solid 2px #fff;
    background-color: #000;
  }

  &.has-scrollbar {
    &:before {
      content: '';
      position: absolute;
      height: 3rem;
      right: 1.8rem;
      bottom: 2px;
      pointer-events: none;
      left: 2px;
      z-index: 2;
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 80%);
    }
    .dropdown {
      &__list {
        padding: 1rem 0.9rem 2rem 0;
      }
    }
  }

  &__list {
    position: relative;
    padding-right: 0.9rem;
  }

  &__item {
    padding: 0.4rem 1.2rem 0.3rem;
    position: relative;
    transition: background-color 180ms var(--e);
    display: block;
    background-color: rgba(255, 255, 255, 0);

    @include hover {
      background-color: rgba(255, 255, 255, 0.3);
    }
  }
}
