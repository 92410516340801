.material {
  &__grid {
    @include portrait {
      margin: 0 -1.5rem;
      display: flex;
      flex-wrap: wrap;
    }
  }

  &__item {
    // padding: 1.9rem 0;
    // transition: width 350ms var(--e), margin 350ms var(--e);
    transition: transform 350ms var(--e), opacity 350ms var(--e), width 350ms var(--e), height 350ms var(--e),
      margin 350ms var(--e);
    overflow: hidden;

    @include portrait {
      width: calc(33.33% - 3rem);
      margin: 0 1.5rem;
    }

    @include tablet {
      width: calc(25% - 3rem);
    }

    @include hover {
      .material {
        &__view {
          opacity: 1;
          transform: scale(1);
        }
      }
    }
    &.is-hidden {
      // transform: scale(0, 0);
      opacity: 0;
      margin: 0;
      height: 0;
      transform: scale(1, 0);
      @include portrait {
      width: 0;
      transform: scale(0, 1);

      }
      // transform: scale(0, 0);
      // padding: 0;
    }
  }

  &__media {
    padding-top: 100%;
    border: solid 1px #fff;
  }

  &__link {
    @include absolute();
    display: none;
  }

  &__view {
    @include absolute();
    @include flex();
    transition: opacity 360ms var(--e), transform 360ms var(--e);
    cursor: pointer;
    transform: scale(1.2);
    font-size: 2rem;
    font-weight: 700;
    color: #000;
    background-color: rgba(255, 255, 255, 0.9);
    opacity: 0;
    z-index: 3;
  }

  &__meta {
    padding: 1.5rem 0;
    position: relative;
  }

  &__row {
  }

  &__label {
  }

  &__value {
    font-weight: 700;
  }

  &__download {
    position: absolute;
    top: 2rem;
    right: 0rem;
    width: 2.9rem;
    display: block;
    height: 2.9rem;
    @include hover {
      &:before {
        transform: translate3d(0, -0.1rem, 0) rotate(45deg);
      }
      &:after {
        transform: translate3d(0, 0.2rem, 0);
      }
    }
  }
}
