.card {
  // padding-left: 1.8rem;
  // border-left: solid 2px #fff;
  padding-left: 2rem;
  margin: 3rem 0;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  align-content: flex-start;
  width: 100%;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 2px;
    background-color: #fff;
    transform-origin: 50% 0%;
    transform: scale(1, 0);
    transition: 800ms transform var(--e) 120ms;
  }

  @include portrait {
    width: 33.3%;
    padding-right: 2.9rem;
  }

  @include tablet {
    width: 25%;

    &--featured {
      width: 75%;
    }
  }

  &__media {
    width: 100%;
    padding-top: 100%;

    filter: grayscale(100%);
    transition: filter 350ms var(--e);

    &--featured {
      @include tablet {
        width: 55.4%;
        height: 100%;
        padding-top: 0;
        // padding-top: 55.3%;
      }

      @include laptop {
        width: 45.6%;
        // padding-top: 45.6%;
      }
    }
  }

  &__title {
    font-size: 2rem;
    line-height: 1.2;
    padding: 1rem 0;

    &--featured {
      @include laptop {
        line-height: 1.13;
        font-size: 4rem;
      }
    }
  }

  &__content {
    padding: 1.6rem 0 0;
    position: relative;

    @include portrait {
      padding: 1.7rem 0 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    @include tablet {
      padding: 1.7rem 0 0;

      &--featured {
        padding: 1.7rem 0 0 3rem;

        width: 44.6%;
        align-self: flex-end;
      }
    }
  }

  &__excerpt {
    display: none;

    @include tablet {
      display: block;
    }
  }

  &__meta {
    width: 100%;
    &--featured {
      @include tablet {
        padding-top: 2.2rem;
      }
      @include laptop {
        display: flex;
        align-items: center;
      }
    }
  }

  &__date {
    white-space: nowrap;
    padding: 0.3rem 0 1.6rem;

    @include portrait {
      padding: 0.3rem 0 2.6rem;
    }
    &--featured {
      @include laptop {
        padding: 0.3rem 0;
        order: 2;
        margin-left: 2rem;
      }
    }
  }

  &__tag {
    display: inline-block;
    padding: 0.5rem 1.7rem 0.2rem;
    border: solid 1px #fff;
    position: relative;
    cursor: pointer;
    z-index: 4;
    transition: color 220ms var(--e), background-color 220ms var(--e);
    background-color: rgba(255, 255, 255, 0);
    @include hover {
      background-color: rgba(255, 255, 255, 0.3);
    }
    &:active {
      color: #000;
      background-color: rgba(255, 255, 255, 1);
    }
  }

  &__link {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 3;
  }

  &__value {
    font-weight: 700;
  }

  @include hover {
    .card {
      &__media {
        filter: grayscale(0%);
      }
    }
  }
  &.is-entering {
    &:before {
      transform: scale(1, 1);
    }
  }
  &.has-entered {
    &:before {
      transform: none;
    }
  }
}
