@font-face {
  font-family: 'Graphik';
  src: url('../fonts/Graphik-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('../fonts/Graphik-RegularItalic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Graphik';
  src: url('../fonts/Graphik-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('../fonts/Graphik-BoldItalic.woff') format('woff');
  font-weight: 700;
  font-style: italic;
}
