.picker {
  &__native {
    @include absolute;
    opacity: 0;
    z-index: 3;
    @include tablet {
      display: none;
    }
  }
  &__select {
    position: relative;
    display: inline-block;
    @include hover {
      .picker {
        &__caret {
          transform: rotate(180deg);
        }
        &__dropdown {
          max-height: 21rem;
          padding: 1rem 0;
        }
      }
    }
  }
  &__trigger {
    font-size: 2rem;
    font-weight: 700;
    line-height: 1.2;
    position: relative;
    display: flex;
    align-items: center;
    z-index: 2;
    pointer-events: none;
    @include portrait {
      line-height: 1.13;
      font-size: 4rem;
    }
  }

  &__caret {
    font-size: 0;
    margin-left: 1rem;
    width: 1rem;
    height: 1rem;
    transition: transform 360ms var(--e);

    svg {
      display: block;
      width: 100%;
    }
  }
}
