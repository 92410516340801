.visual {
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;

  &__overlay {
    @include absolute;
    opacity: 0;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 10%, rgba(0, 0, 0, 0.7) 100%);
  }

  &__video {
    background-color: #000;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: 50% 50%;
    display: block;
    opacity: 0;
    // &.is-suspended {
    //   display: none;
    // }
    &.is-playing {
      opacity: 1;
    }
    @include tablet {
      opacity: 1;
    }
  }
}
