.footer {
  padding: 4.1rem 0;

  &__container {
  }

  &__quick-links {
    text-align: center;
    padding: 1rem 0;

    @include portrait {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  &__quick-link {
    display: block;
    padding: 0.8rem 2rem 0.9rem;
    font-size: 2rem;
    &:after {
      bottom: .8rem;
      left: 2rem;
      right: 2rem;
    }
  }

  &__socials {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    padding: 2.6rem 0;
    @include portrait {
      padding: 1.3rem 0;
    }
  }

  &__social {
    display: block;
    width: 3.2rem;
    height: 3.2rem;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50% 50%;
    margin: 1.6rem;
    transform-origin: 50% 50%;
    transition: transform 360ms var(--e), opacity 360ms var(--e);

    @include portrait {
      margin: 1rem;
      width: 2.2rem;
      height: 2.2rem;
    }

    @include hover {
      transform: scale(0.98);
      opacity: 0.8;
    }
  }

  &__partners {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    padding: 1.4rem 0;

    @include portrait {
      padding: 0.6rem 0;
    }
  }

  &__partner {
    display: block;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50% 50%;
    height: 2.4rem;
    margin: 1rem 3%;
    width: 44%;
    &--s {
      height: 1.6rem;
    }
    &--l {
      height: 3.4rem;
    }

    @include portrait {
      margin: 1rem 1.3%;
      width: 18%;
    }
  }

  &__copyright {
    text-align: center;
    padding: 1.4rem 0;
  }
}
