.categories {
  &__top {
    padding: 0.6rem 0;
  }
  &__items {
    padding: 1rem 0;
    position: relative;
    @include portrait {}
    @include tablet {
    padding-left: 33.33%;

    }
    @include laptop {
    padding-left: 25%;

    }
  }
  &__filter {
    @include portrait {
    width: calc(33.33% - 3rem);
    }
    @include laptop {
    width: calc(25% - 3rem);

    }
  }
  &__title {
    padding: 2rem 0 0;
    @include portrait {
    position: absolute;
    top: -7rem;
    left: 33.33%;
  }
    @include laptop {
      left: 25%;
    }
  }

  &__item {
    display: none;
    &.is-active {
      display: block;
    }
  }

  &__title {
    font-size: 4rem;
    line-height: 1.13;
  }

  &__blocks {
    @include portrait {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -1.5rem;
    }
  }

  &__block {
    padding: 0.9rem 0 0;

    @include portrait {
      padding: 0.9rem 0 0;
      margin: 0 1.5rem;
      width: calc(33.33% - 3rem);
    }

    @include tablet {
      width: calc(50% - 3rem);
    }

    @include laptop {
      width: calc(33.33% - 3rem);
    }
  }
}
