.bscroll-vertical-scrollbar {
  opacity: 1 !important;
  transition: none !important;
  width: 1.8rem !important;
  overflow: visible !important;
  transform: translateY(-3px);
}

.bscroll-indicator {
  z-index: 2;
  width: 1.8rem !important;
  right: 0;
  background-color: #fff !important;
  height: auto !important;
  min-height: 3.6rem;
  border-radius: 0 !important;
  border: none !important;
  width: 1.8rem !important;
  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 1rem;
    height: 1rem;
    border-bottom: solid 1px #000;
    border-right: solid 1px #000;
    left: 0.4rem;
  }
  &:before {
    transform: rotate(-135deg);
    top: 0.8rem;
  }
  &:after {
    transform: rotate(45deg);
    bottom: 0.8rem;
  }
}
